import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/main",
    component: HomeView,
    children: [
      {
        path: "/main",
        name: "main",
        component: () => import("../views/MainContent.vue"),
      },
      {
        path: "/about",
        name: "about",
        component: () => import("../views/AboutView.vue"),
      },
      {
        path: "/protocol",
        name: "protocol",
        component: () => import("../views/Protocol.vue"),
      },
      {
        path: "/agreement",
        name: "agreement",
        component: () => import("../views/Agreement.vue"),
      },
    ],
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
