<template>
  <div class="home">
    <div v-if="showMenu" class="top_container">
      <div class="top flex">
        <div class="logo">
          <img src="../assets/logo.png" alt="" />
        </div>
        <div class="title">趣划桨APP</div>
        <div class="nav flex">
          <span @click="$router.push('/')">首页</span>
          <span @click="$router.push('/about')">关于我们</span>
        </div>
      </div>
    </div>

    <router-view></router-view>
    <div v-if="showMenu" class="footer_container">
      <div class="footer">
        <div class="footer_top">
          <div class="logo">
            <img src="../assets/logo.png" alt="" />
          </div>
          <div class="title">趣划桨APP</div>
          <div class="footer_nav">
            <span
              @click="
                scrollTop();
                $router.push('/');
              "
              >首页</span
            >
            <span
              @click="
                scrollTop();
                $router.push('/about');
              "
              >关于我们</span
            >
            <span
              @click="
                scrollTop();
                $router.push('/protocol');
              "
              >隐私政策</span
            >
            <span
              @click="
                scrollTop();
                $router.push('/agreement');
              "
              >用户协议</span
            >
          </div>
        </div>
        <div class="text">
          <div class="f-text">
            <a href="https://beian.miit.gov.cn/#/">备案号：苏ICP备2024062088号-1</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenu: true,
    };
  },
  //监听路由变化 当为protocol和agreement时隐藏导航栏
  watch: {
    $route(to, from) {
      if (to.path === "/protocol" || to.path === "/agreement") {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    },
  },
  mounted() {
    if (this.$route.path === "/protocol" || this.$route.path === "/agreement") {
      this.showMenu = false;
    } else {
      this.showMenu = true;
    }
  },
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
      });
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
}
.border {
  border-bottom: 1px #dfdfdf solid;
}
.top_container {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 1001;
}
.top {
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}

.nav span {
  font-size: 20px;
  width: 100px;
  height: 100px;
  display: block;
  line-height: 100px;
  text-align: center;
  cursor: pointer;
  border-left: 1px #eaeaea solid;
  border-right: 1px #eaeaea solid;
}
.title {
  flex: 1;
}
.nav span:last-child {
  border-left: none;
}

.footer_container {
  background: #f1f1f1;
  padding: 50px 0;
}
.footer {
  max-width: 1200px;
  margin: 0 auto;
}
.footer_top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.footer .logo {
  width: 200px;
}
.logo {
  width: 100px;
  margin-right: 20px;
}
.logo img {
  width: 100%;
  height: 100%;
  display: block;
}
.footer_nav span {
  font-size: 14px;
  color: #999;
  margin-right: 50px;
  cursor: pointer;
}
.f-text {
  font-size: 14px;
  color: #999;
  margin-bottom: 5px;
  cursor: pointer;
}
.f-text a {
  color: inherit;
  text-decoration: none;
}
</style>
