var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[(_vm.showMenu)?_c('div',{staticClass:"top_container"},[_c('div',{staticClass:"top flex"},[_vm._m(0),_c('div',{staticClass:"title"},[_vm._v("趣划桨APP")]),_c('div',{staticClass:"nav flex"},[_c('span',{on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v("首页")]),_c('span',{on:{"click":function($event){return _vm.$router.push('/about')}}},[_vm._v("关于我们")])])])]):_vm._e(),_c('router-view'),(_vm.showMenu)?_c('div',{staticClass:"footer_container"},[_c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer_top"},[_vm._m(1),_c('div',{staticClass:"title"},[_vm._v("趣划桨APP")]),_c('div',{staticClass:"footer_nav"},[_c('span',{on:{"click":function($event){_vm.scrollTop();
              _vm.$router.push('/');}}},[_vm._v("首页")]),_c('span',{on:{"click":function($event){_vm.scrollTop();
              _vm.$router.push('/about');}}},[_vm._v("关于我们")]),_c('span',{on:{"click":function($event){_vm.scrollTop();
              _vm.$router.push('/protocol');}}},[_vm._v("隐私政策")]),_c('span',{on:{"click":function($event){_vm.scrollTop();
              _vm.$router.push('/agreement');}}},[_vm._v("用户协议")])])]),_vm._m(2)])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"f-text"},[_c('a',{attrs:{"href":"https://beian.miit.gov.cn/#/"}},[_vm._v("备案号：苏ICP备2024062088号-1")])])])
}]

export { render, staticRenderFns }